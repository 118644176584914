.header-container {
    padding: 1rem 0rem;
    width: 100%;
    display: flex;
    flex: 1;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 5px 4px lightgray;
}

.header-title {
    display: flex;
    flex-flow: row;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 600;
    color: black;
    text-decoration: none;
    margin-left: 1rem;
}

.header-title:hover {
    text-decoration: underline;
    text-decoration-color: navy;
    cursor: pointer;
}

.header-actions {
    width: 10rem;
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    gap: 1rem;
    margin-right: 1rem;

    .social-icon {
        color: black;
        &:hover {
            color: navy;
        }
    }

}