@import "./styles/fonts.css";

.app {
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-flow: column;
  font-family: Inter, sans-serif;
  overflow-y: auto;
  align-items: center;
}


@media only screen and (max-width: 850px) {
    .intro__container {
        flex-flow: column;
    }

    .intro__img {
        max-width:50%;
    }

    .intro__text {
        padding-top: 1rem;
        font-size: 1rem;
    }
}
