.hero {
    display: grid;
}

@media only screen and (min-width: 800px) {
    .hero {
        grid-template-areas: "text image";
        grid-template-columns: 4fr 2fr;
        column-gap: 15%;
        padding-top: 5rem;
    }
}

@media only screen and (max-width: 800px) {
    .hero {
        display: grid;
        grid-template-areas: 
            "text"
            "image";
        grid-template-rows: 2fr 2fr;
        padding: 5rem 0rem 5rem 0rem;
    }
}

.hero-text {
    grid-area: text;
    display: flex;
    flex-flow: column;
    justify-content: center;

    .title {
        font-size: 3rem;
        margin-bottom: 1.5rem;

        .emoji {
            margin-left: 1rem;
        }
    }

    .subtitle {
        font-size: 1.5rem;
    }
    
}

.headshot {
    grid-area: image;
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;

    .image {
        max-width: 100%;
        min-width: 50%;

        height: auto;
        border-radius: 50%;
    }
}

