.section-container {
    display: grid;
}

@media only screen and (min-width: 800px) {
    .section-container {
        margin-top: 5rem;
        grid-template-areas: "details list";
        grid-template-columns: 1fr 3fr;
    }
}

@media only screen and (max-width: 800px) {
    .section-container {
        grid-template-areas: "details" "list";
        grid-template-rows: 1fr auto;
    }

    .section-details {
        margin-bottom: 1rem;
    }

    ul {
        padding-left: 0px;
        padding-bottom: 5rem;
    }
}


.section-details {
    grid-area: details;

    .title {
        font-weight: 600;
        margin-bottom: 1rem;
        font-size: 1.5rem;
    }

    .description {
        font-style: italic;
    }
}

.article-list {
    grid-area: list;
    list-style: none;
    margin: 0rem;

    > li {
        border-top: 1px solid black;
        font-size: 1.5rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        
        > a {
            text-decoration: none;
            color: black;
        }
        
        > a:hover {
            color: navy;
            cursor: pointer;
        }
    }

    > li:last-child {
        border-bottom: 1px solid black;
    }


}